<template>
    <v-container fluid tag="section">
        <validation-observer ref="observer" slim v-slot="{ invalid }">
            <v-row align="center">
                <v-col cols="12">
                    <base-material-card color="primary" icon="mdi-export" :title="title" inline class="px-5 py-4 ml-0">
                        <v-form class="mt-5">
                            <v-row align="center" class="mx-auto">
                                <v-col cols="6">
                                    <validation-provider v-slot="{ errors }" rules="required">

                                        <v-text-field :error-messages="errors" label="Título" outlined
                                            v-model="novaExportacao.title" />
                                    </validation-provider>

                                </v-col>
                                <v-col cols="6">
                                    <validation-provider v-slot="{ errors }" rules="required">
                                        <v-select :error-messages="errors"
                                            @change="novaExportacao.filters.temas_selecionados = []" :items="[
            { value: 'indger_compilado', text: 'INDGER Compilado', },
            { value: 'comercial_v2', text: 'Comercial V2', },
            { value: 'servicos_v2', text: 'Serviços V2', },
            { value: 'reclamacao', text: 'Reclamações', },
            { value: 'tec_al', text: 'Alimentadores', },
            { value: 'tec_sub', text: 'Subestações', },
            { value: 'tec_ld', text: 'Linhas Distribuições' },
        ]" outlined v-model="novaExportacao.base" label="Base"></v-select>
                                    </validation-provider>

                                </v-col>
                            </v-row>
                        </v-form>
                    </base-material-card>
                </v-col>
                <v-col cols="12" v-if="novaExportacao.base">
                    <base-material-card color="primary" icon="mdi-filter" title="Filtros e parâmetros" inline
                        class="px-5 py-4 ml-0">
                        <v-form class="mt-5">
                            <v-row class="mx-auto">
                                <v-col class="pb-0 pt-1" cols="6">
                                    <input-month :minMonth="minMonth" :maxMonth="maxMonth" label="Competência de*"
                                        rules="required|min:7" @monthSelected="setDataDe"
                                        :selectedMonth="novaExportacao.filters.data_de" />
                                </v-col>
                                <v-col class="pb-0 pt-1" cols="6">
                                    <input-month :maxMonth="maxMonth" :minMonth="minMonth" label="Competência até*"
                                        rules="required|min:7" @monthSelected="setDataAte"
                                        :selectedMonth="novaExportacao.filters.data_ate" />
                                </v-col>
                                <v-col class="pb-0 pt-1" cols="12">
                                    <v-text-field type="number" min="0" label="Versão do INDGER" hide-details
                                        prepend-icon="mdi-numeric-1-box-multiple-outline"
                                        v-model="novaExportacao.filters.versao" />

                                </v-col>
                            </v-row>


                            <v-row class="mx-auto my-5" v-if="novaExportacao.base != 'reclamacao' && novaExportacao.base != 'indger_compilado'">
                                <v-col cols="12">
                                    <v-expansion-panels :value="0">
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                Temas
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row class="">

                                                    <v-col cols="auto">
                                                        <strong
                                                            v-show="novaExportacao.filters.temas_selecionados.length == 0"
                                                            style="color: red;">Selecione pelo menos 1 tema</strong>
                                                        <v-checkbox label="Selecionar todos os temas"
                                                            v-model="selectAllTemas" class="mt-0" hide-details />
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">

                                                    <v-col class="" cols="auto"
                                                        v-for="category in formattedFieldsCategories" :key="category.slug">
                                                        <v-checkbox v-model="novaExportacao.filters.temas_selecionados"
                                                            :label="category.category" hide-details
                                                            :value="category.slug" />
                                                    </v-col>


                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                            <!-- <v-row class="mx-auto my-5" v-if="novaExportacao.base == 'comercial_v2' || novaExportacao.base == 'servicos_v2'">
                                <v-col cols="12">
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                Bases Brutas
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row v-if="temas_selecionaveis_importacao_base_bruta.length > 0">
                                                    <v-col cols="auto">
                                                        <v-checkbox label="Selecionar todos os temas"
                                                            v-model="selectAllBasesBrutas" class="mt-0" hide-details />
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="temas_selecionaveis_importacao_base_bruta.length > 0">

                                                    <v-col cols="auto" v-for="tema in temas_selecionaveis_importacao_base_bruta"
                                                        :key="tema.value">
                                                        <v-checkbox
                                                            v-model="novaExportacao.filters.temas_selecionados_importacao_base_bruta"
                                                            :label="tema.label" :value="tema.value" />
                                                    </v-col>
                                                </v-row>
                                                <v-row class="mx-auto mt-3" v-else>
                                                    <div>
                                                        Selecione acima ao menos 1 <a href="javascript:void(0)"
                                                            @click.stop="dialog_temas_com_base_bruta = true">tema com base
                                                            bruta</a> importável.
                                                    </div>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row> -->
                            <v-row class="mx-auto my-5" v-if="novaExportacao.base != 'reclamacao' && novaExportacao.base != 'indger_compilado'">
                                <v-col cols="12">
                                    <v-expansion-panels>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                Validações
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>

                                                <v-alert dense outlined type="info" class="mb-0 py-1 px-1">
                                                    <strong>Importante:</strong> Somente a versão zero (versão de trabalho) possui dados de validações.
                                                </v-alert>
                                                <v-row class="">
                                                    <v-col cols="auto">
                                                        <v-checkbox label="Incluir Validações"
                                                            v-model="novaExportacao.filters.validacao" class="mt-0"
                                                            hide-details />
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>

                        </v-form>
                    </base-material-card>
                </v-col>
                <v-col cols="12">
                    <v-btn id="btn-save-exportacao" color="success" min-width="100" style="float: right;"
                        :disabled="!isValid" :loading="loading" @click.once="salvarExportacao()">
                        Salvar
                    </v-btn>
                </v-col>
            </v-row>
        </validation-observer>
        <v-dialog max-width="480" v-model="dialog_temas_com_base_bruta">
            <v-card>
                <v-card-title class="display-2">
                    Temas que possuem Base Bruta
                </v-card-title>
                <v-card-text>
                    <ul>
                        <li v-for="tema in temas_validos_importacao_base_bruta[novaExportacao.base]" :key="tema.value">
                            {{ tema.label }}
                        </li>
                    </ul>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="secondary" text @click="dialog_temas_com_base_bruta = false">
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import tourMixins from '@/mixins/tourMixins';
import components from '@/components/relatorios/forms/components.js';
import UsersService from '@/services/UsersService';
import ExportacaoDadosService from '@/services/ExportacaoDadosService';

export default {
    components: {
        components,
        InputMonth: () => import('@/components/general/InputMonth.vue'),

    },
    mixins: [
        tourMixins
    ],
    data() {
        return {
            slug: null,

            relatorio: {
                nome: null
            },
            dialog_temas_com_base_bruta: false,
            loading: false,
            novaExportacao: {
                title: '',
                base: '',
                filters: {
                    data_ate: '',
                    data_de: '',
                    versao: 0,
                    temas_selecionados: [],
                    temas_selecionados_importacao_base_bruta: [],
                    validacao: false
                },
            },
            temas_selecionados: [],
            indicadoresFieldsCategories: {},
            temas_validos_importacao_base_bruta: {
                comercial_v2: [
                    { label: 'Substituição de Medidores', value: 'SubstituicaoDeMedidores' },
                    { label: 'Suspensão Indevida', value: 'SuspensaoIndevida' },
                    { label: 'Estrutura de Atendimento', value: 'EstruturaDeAtendimento' },
                    { label: 'Danos Elétricos', value: 'DanosEletricos' },
                    { label: 'Recuperação de Receita', value: 'RecuperacaoDeReceita' },
                ],
                servicos_v2: [
                    { label: 'Realizados', value: 'Realizados' },
                    { label: 'Entrantes', value: 'Entrantes' },
                    { label: 'Suspensos', value: 'Suspensos' },
                    { label: 'Pendentes', value: 'Pendentes' },
                    { label: 'Compensados', value: 'Compensados' }
                ]
            },
            temas_selecionados_importacao_base_bruta: [],
            minMonth: '',
            maxMonth: '',
            selectAllTemas: false,
            selectAllBasesBrutas: false,
            errors: []
        }
    },
    mounted() {
        this.getIndicadoresFieldsCategories();
        this.verificarDisponibilidade();
    },
    created() {
        if (this.$route.params.id != undefined) {
            ExportacaoDadosService.getExportacaoById(this.$route.params.id).then((response) => {
                this.novaExportacao = response.data.data;
                if (this.novaExportacao.filters != null) {
                    this.novaExportacao.filters = JSON.parse(this.novaExportacao.filters)
                }
            })
        }
    },
    computed: {
        base() {
            return this.novaExportacao.base;
        },
        temas_selecionaveis_importacao_base_bruta() {
            return this.novaExportacao.base && this.novaExportacao.filters.temas_selecionados ? this.temas_validos_importacao_base_bruta[this.novaExportacao.base]
                .filter(tema => this.novaExportacao.filters.temas_selecionados.includes(tema.value)) : []
        },
        title() {
            return `Nova Exportação `;
        },
        formattedFieldsCategories() {
            if (!this.novaExportacao?.base) return [];

            const fieldsCategories = {
                "comercial_v2": this.indicadoresFieldsCategories?.fields_indicadores_comerciais_v2_categories?.map(category => {
                    return {
                        slug: category.slug,
                        category: category.category
                    }
                }),
                "servicos_v2": this.indicadoresFieldsCategories?.fields_indicadores_servicos_v2_categories?.map(category => {
                    return {
                        slug: category.slug,
                        category: category.category
                    }
                }),
                "tec_al": this.indicadoresFieldsCategories?.fields_indicadores_alimentadores_categories?.map(category => {
                    return {
                        slug: category.slug,
                        category: category.category
                    }
                }),
                "tec_sub": this.indicadoresFieldsCategories?.fields_indicadores_subestacoes_categories?.map(category => {
                    return {
                        slug: category.slug,
                        category: category.category
                    }
                }),
                "tec_ld": this.indicadoresFieldsCategories?.fields_indicadores_linhas_distribuicao_categories?.map(category => {
                    return {
                        slug: category.slug,
                        category: category.category
                    }
                }),
            };

            return fieldsCategories[this.novaExportacao?.base] ?? [];
        },

        isValid() {
            let baseValidation = this.novaExportacao.title != '' && this.novaExportacao.base != '' && this.novaExportacao.filters.data_ate != '' && this.novaExportacao.filters.data_de != '';
            if (this.novaExportacao.base != 'reclamacao' && this.novaExportacao.base != 'indger_compilado') {
                baseValidation = baseValidation && this.novaExportacao.filters.temas_selecionados.length > 0;
            }
            let dataMax = new Date(this.maxMonth.split('-'));
            let dataMin = new Date(this.minMonth.split('-'));
            let dataAte = new Date(`${this.novaExportacao.filters.data_ate}-01 `.split('-'));
            let dataDe  = new Date(`${this.novaExportacao.filters.data_de}-01 `.split('-'));

            if(
                ((dataDe < dataMin || dataDe > dataMax)   && this.novaExportacao.filters.data_de  != '')  ||
                ((dataAte > dataMax || dataAte < dataMin) && this.novaExportacao.filters.data_ate != '')
            )
            {
                this.$toast.warning('Este intervalo de datas não possui dados válidos' ,'', {
                    position: 'topRight'
                });
                return false
            }
            return baseValidation
        }

    },
    watch: {
        selectAllTemas(newValue, oldValue) {
            if (newValue) {
                for (let category of this.formattedFieldsCategories) {
                    this.novaExportacao.filters.temas_selecionados.push(category.slug)
                }
            } else {
                this.novaExportacao.filters.temas_selecionados = []
            }
        },
        selectAllBasesBrutas(newValue, oldValue) {
            if (newValue) {
                for (let item of this.temas_selecionaveis_importacao_base_bruta) {
                    this.novaExportacao.filters.temas_selecionados_importacao_base_bruta.push(item.value)
                }
            } else {
                this.novaExportacao.filters.temas_selecionados_importacao_base_bruta = []
            }
        },
        base(newValue, oldValue) {
            if(oldValue != ''){
                this.novaExportacao.filters.temas_selecionados = [];
                this.novaExportacao.filters.temas_selecionados_importacao_base_bruta = [];
            }
            
        }
    },
    methods: {
        salvarExportacao() {
            if (this.$route.params.id) {
                ExportacaoDadosService.editarExportacao(this.novaExportacao).then((response) => {
                    this.$toast.success(`Exportação editada com sucesso.`,  '', {
                        position: 'topRight'
                    });
                    this.$router.push('/dados/exportacoes-indger/historico')
                    isValid = false;
                }).catch((error) => {
                    this.$toast.error(error.error,'', {
                        position: 'topRight'
                    });
                });
            } else {
                ExportacaoDadosService.salvarExportacao(this.novaExportacao).then((response) => {
                    this.$toast.success(`Exportação salva com sucesso.`, '', {
                        position: 'topRight'
                    });
                    this.$router.push('/dados/exportacoes-indger/historico')
                    isValid = false;

                }).catch((error) => {
                    this.$toast.error(error.error ,'', {
                        position: 'topRight'
                    });
                });
            }
        },
        getIndicadoresFieldsCategories() {
            return UsersService.getIndicadoresFieldsCategories()
                .then(res => {
                    this.indicadoresFieldsCategories = res;
                })
                .catch(err => {
                    this.$toast.error('Erro ao recuperar temas dos indicadores.', '', {
                        position: 'topRight'
                    });
                    throw err;
                });
        },
        verificarDisponibilidade() {

            return ExportacaoDadosService.verificarDisponibilidade()
                .then(res => {
                    this.minMonth = res.data.data.data_min;
                    this.maxMonth = res.data.data.data_max;

                })
                .catch(err => {
                    this.$toast.error('Erro ao recuperar temas dos indicadores.', '', {
                        position: 'topRight'
                    });
                    throw err;
                });
        }
        ,
        setDataDe(data) {
            this.novaExportacao.filters.data_de = data;
        },
        setDataAte(data) {
            this.novaExportacao.filters.data_ate = data;
        },

    }
}

</script>